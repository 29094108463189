import * as SentryBrowser from '@sentry/browser';

const Sentry = {
    init: config => {
        SentryBrowser.init({
            dsn: config.dsn,
            environment: config.environment,
            release: config.release,
            ignoreErrors: [
                'Non-Error promise rejection captured with keys',
                // eslint-disable-next-line prettier/prettier
                'undefined is not an object (evaluating \'ceCurrentVideo.currentTime\')',
                "Unable to get property 'round' of undefined or null reference",
                'NS_ERROR_NOT_INITIALIZED',
            ],
            blacklistUrls: [
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net/i,
                // Chrome extensions
                /extensions\//i,
                /chrome-extension/i,
                /^chrome:\/\//i,
                /ampproject/i,
            ],
        });
    },
    setUser: user => {
        SentryBrowser.setUser(user);
    },
    captureMessage: msg => {
        SentryBrowser.captureMessage(msg);
    },
    captureException: err => {
        SentryBrowser.captureException(err);
    },
    configureScope: func => {
        SentryBrowser.configureScope(func);
    },
    withScope: func => {
        SentryBrowser.withScope(func);
    },
};

// Attempt to supress uncaught promises... appear to be caused by adblocker
window.onunhandledrejection = event => {
    event.preventDefault ? event.preventDefault() : (event.returnValue = false);
};

window.Sentry = Sentry; // Continue using globally until everything is in react
//export default Sentry;
